import React from 'react';
import Layout from '../components/layout/Layout';
import RsvpForm from '../components/RsvpForm';

const AboutPage = () => (
    <Layout>
        <RsvpForm />
    </Layout>
);

export default AboutPage;
