import React from 'react';
import styled from '@emotion/styled';
import { MEDIA_QUERIES } from '../utils/breakpoints';
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from '../constants';

const FormWrapper = styled.div`
    height: calc(100vh - ${MOBILE_HEADER_HEIGHT}px);
    margin-bottom: 32px;
    overflow: hidden;

    ${MEDIA_QUERIES.tablet} {
        height: calc(100vh - ${HEADER_HEIGHT}px);
    }
`;

const Frame = styled.iframe`
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

const RsvpForm = () => (
    <FormWrapper>
        <Frame
            src="https://docs.google.com/forms/d/e/1FAIpQLSeJuPBaiNYAod9Q0cs2qLRgkP3bDTKa24nqc4Fh08iTMiSZgA/viewform?embedded=true"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
            title="RSVP Form"
        >
            Chargement…
        </Frame>
    </FormWrapper>
);

// return <IframeStyled onLoad={handleIframeLoad} src="https://josephine-theo.rsvpify.com?embed=1" />;
export default RsvpForm;
